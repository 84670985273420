.slider {
	position: relative;
	margin-top: 0px;
}

.navigation {
	position: -webkit-sticky;
	position: sticky;
	left: 0%;
	top: 0px;
	right: 0%;
	bottom: auto;
	z-index: 2000;
	margin-bottom: 0px;
}

.slider-2 {
	height: 650px;
	overflow-y: hidden;
}

.link-block {
	display: flex;
	width: 15%;
	height: 200px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 450ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #383838;
	font-weight: 300;
	text-decoration: none;
}

.link-block:hover {
	transform: scale(1.1);
}

.icon-row {
	display: flex;
	padding-top: 60px;
	padding-bottom: 60px;
	justify-content: space-around;
	transform: translate(0px, 0px);
}

.service-icon {
	width: 100%;
	max-width: 120px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.service-icon.calculator {
	width: 28%;
}

.service-title {
	margin-top: 10px;
	font-family: Montserrat, sans-serif;
	color: #213064;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;
	text-align: center;
	text-transform: none;
}

.service-title.mortgage {
	font-family: Montserrat, sans-serif;
	color: #213064;
	font-weight: 600;
	text-align: center;
}

.service-title.atm {
	color: #213064;
	font-weight: 600;
}

.service-title.mobile-banking {
	color: #213064;
}

.service-title.acount {
	color: #213064;
}

.top-nav {
	display: flex;
	height: auto;
	padding: 20px 100px;
	justify-content: space-between;
	align-items: center;
}

.navbar {
	position: static;
	display: flex;
	padding-top: 0px;
	padding-bottom: 0px;
	flex-direction: column;
	background-color: #fff;
}

.brand {
	max-width: 300px;
}

.nav-link {
	margin-right: 0px;
	margin-left: 0px;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	line-height: 0px;
	font-weight: 600;
}

.nav-link:hover {
	color: #4f95d0;
}

.nav-link:active {
	flex: 0 auto;
}

.nav-link-row {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	display: block;
	justify-content: space-around;
	align-items: center;
}

.nav-menu {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 200;
	display: flex;
	width: 100%;
	margin-top: 98px;
	justify-content: space-around;
	align-items: center;
	background-color: #e7e7e7;
}

.slide-arrow {
	font-size: 24px;
}

.slide-arrow-2 {
	font-size: 24px;
}

.left-arrow {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	height: 50px;
}

.right-arrow {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	height: 50px;
}

.slide-nav {
	height: 50px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.button {
	display: flex;
	height: auto;
	padding: 9px 25px 9px 40px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #84d5f7;
	border-radius: 100px;
	background-color: #3079b5;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 7% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.button:hover {
	background-color: #213064;
}

.slide {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.login-field {
	width: 150px;
	height: 30px;
	margin-right: 5px;
	margin-bottom: 0px;
	float: left;
	border-radius: 3px;
	background-color: #e8f0fe;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

.login-form-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: initial;
	align-items: flex-start;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 20px 40px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #fff;
	opacity: 0;
	transform: translate(0px, -105px);
}

.login-field-label {
	display: block;
	height: 30px;
	margin-right: 20px;
	margin-bottom: 0px;
	float: left;
	font-family: Montserrat, sans-serif;
	color: #777;
	font-size: 14px;
	line-height: 27px;
	font-weight: 500;
}

.login-div {
	position: absolute;
	top: 0px;
	right: 0px;
	overflow: hidden;
	margin-top: 37.4px;
	margin-right: 20px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: transparent;
	color: #505050;
}

.button-2 {
	display: inline-block;
	padding: 4px 15px;
	background-color: #000;
	font-family: Exo, sans-serif;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
}

.button-2.login-button {
	display: block;
	height: 30px;
	padding-top: 3px;
	padding-bottom: 0px;
	float: left;
	border-radius: 3px;
	background-color: #4f95d0;
	transition: all 300ms ease;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.button-2.login-button:hover {
	background-color: #4160c6;
}

.button-2.login-button {
	display: block;
	height: 30px;
	padding-top: 0;
	padding-bottom: 0px;
	float: left;
	border: 2px solid transparent;
	border-radius: 3px;
	background-color: #4f95d0;
	transition: all 300ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}

.button-2.login-button:hover {
	border-style: solid;
	border-width: 2px;
	border-color: #4f95d0;
	background-color: #fff;
	color: #4f95d0;
}

.news-section {
	display: flex;
	padding: 60px 140px 40px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.div-block-2 {
	display: flex;
	width: 30%;
	height: auto;
	flex-direction: column;
	align-items: flex-start;
	background-color: transparent;
}

.div-block-3 {
	overflow: hidden;
	height: 16vw;
	width: 100%;
	border-radius: 5px;
}

.heading-2 {
	margin-top: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 26px;
	line-height: 35px;
	font-weight: 600;
}

.image-3 {
	width: 100%;
	max-width: none;
	height: 16vw;
	border-radius: 5px;
}

.image-3.img-zoom {
	transition: all 450ms ease;
}

.image-3.img-zoom:hover {
	transform: scale(1.1);
}

.div-block-4 {
	overflow: hidden;
	height: 16vw;
	width: 100%;
	border-radius: 5px;
}

.div-block-5 {
	overflow: hidden;
	height: 16vw;
	width: 100%;
	border-radius: 5px;
}

.button-3 {
	padding: 0px;
	background-color: transparent;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #3079b5;
	font-weight: 600;
}

.button-3:hover {
	padding-right: 0px;
}

.utility-page-wrap {
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	justify-content: center;
	align-items: center;
}

.utility-page-content {
	display: flex;
	width: 260px;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.search {
	display: flex;
	overflow: hidden;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	align-items: center;
	opacity: 1;
}

.search-input {
	height: 38px;
	margin-right: -38px;
	margin-bottom: 0px;
	border-radius: 20px;
}

.search-button {
	width: 38px;
	height: 38px;
	margin-right: 0px;
	margin-left: 0px;
	border-radius: 20px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
}

.search-button:hover {
	background-color: #2f6ca0;
}

.div-block-6 {
	display: flex;
	overflow: hidden;
	width: auto;
	height: 45px;
	margin-bottom: 20px;
	align-items: center;
}

.grey-arrow {
	width: 0px;
	margin-left: 10px;
}

.div-block-7 {
	position: static;
	overflow: hidden;
	width: auto;
	height: auto;
}

.div-block-7:hover {
	width: auto;
	height: auto;
}

.div-block-8 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.arrow-2 {
	position: static;
	overflow: hidden;
	width: auto;
	height: auto;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.grey-arrow-2 {
	width: 0px;
	margin-left: 10px;
}

.grey-arrow-3 {
	width: 0px;
	margin-left: 10px;
}

.image-4 {
	width: 120%;
	max-width: none;
}

.image-5 {
	width: 120%;
	max-width: none;
}

.callout-image {
	display: flex;
	height: 450px;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/20181016082423/assets/images/home-slider4.jpg');
	background-position: 0px 0px, 50% 72%;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
}

.slider-graphic {
	display: -ms-grid;
	display: grid;
	overflow: hidden;
	height: auto;
	padding-right: 0px;
	padding-bottom: 100px;
	padding-left: 100px;
	flex-direction: column;
	justify-content: flex-end;
	justify-items: center;
	align-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
}

.slider-graphic.slider-2 {
	background-image: url('/20181016082423/assets/images/home-slider3.jpg');
}

.image-6 {
	display: none;
	height: 159%;
	margin-top: -257px;
	margin-left: -6px;
	opacity: 1;
}

.slide-2 {
	background-position: 0px 0px, 50% 28%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.heading-3 {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 50px;
	line-height: 55px;
	font-weight: 800;
	text-align: center;
}

.text-block {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 25px;
	line-height: 40px;
	font-weight: 700;
	text-align: center;
}

.blue-button {
	display: flex;
	height: auto;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 9px 25px;
	align-items: center;
	border-style: solid;
	border-width: 2px;
	border-color: #3079b5;
	border-radius: 100px;
	background-color: #3079b5;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.blue-button:hover {
	border-style: solid;
	border-color: #213064;
	background-color: #fff;
	color: #3079b5;
}

.text-block-2 {
	font-family: Montserrat, sans-serif;
}

.text-block-3 {
	font-family: Montserrat, sans-serif;
}

.text-block-4 {
	font-family: Montserrat, sans-serif;
}

.heading-4 {
	margin-top: 140px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 50px;
	font-weight: 800;
	text-transform: none;
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
}

.text-block-5 {
	max-width: 600px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
	text-shadow: none;
}

.testimonial {
	display: flex;
	height: 600px;
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	background-color: #fff;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/20181016082423/assets/images/AdobeStock_274989153_Preview.jpeg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.footer-container {
	display: flex;
	height: auto;
	padding-right: 140px;
	padding-left: 140px;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.05);
}

.paragraph-2 {
	max-width: 600px;
	margin-bottom: 0px;
	padding-left: 0px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
}

.heading-6 {
	padding-left: 60px;
	background-image: url('/20181016082423/assets/images/Testimonial-icon.svg');
	background-position: 0% 50%;
	background-size: 45px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 45px;
	font-weight: 700;
}

.div-block-12 {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.heading-7 {
	margin-bottom: 20px;
	margin-left: 0px;
	padding-left: 60px;
	background-image: url('/20181016082423/assets/images/News-icon.svg');
	background-position: 0% 50%;
	background-size: 45px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	font-size: 45px;
	line-height: 50px;
	font-weight: 700;
}

.div-block-14 {
	display: flex;
	width: 60%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.heading-8 {
	display: flex;
	margin-left: 40px;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.slider-4 {
	width: 663px;
	height: auto;
	background-color: transparent;
}

.right-arrow-2 {
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	width: 20px;
	height: auto;
	margin-bottom: 0px;
}

.left-arrow-2 {
	left: 0%;
	top: 0%;
	right: auto;
	bottom: 0%;
	width: 20px;
	height: auto;
	margin-bottom: 0px;
}

.icon-5 {
	font-size: 20px;
}

.icon-6 {
	font-size: 20px;
}

.slide-nav-3 {
	display: none;
	height: 20px;
	padding-top: 10px;
	font-size: 10px;
}

.slide-3 {
	height: auto;
	min-height: auto;
	padding-left: 40px;
}

.slide-4 {
	margin-bottom: 0px;
	padding-left: 40px;
}

.mask-2 {
	height: auto;
}

.contact-us {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.text-block-7 {
	max-width: 1300px;
	padding-top: 40px;
	padding-bottom: 0px;
	font-family: Montserrat, sans-serif;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
}

.button-no-outline {
	display: flex;
	height: auto;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 9px 25px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #3079b5;
	border-radius: 100px;
	background-color: #3079b5;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.button-no-outline:hover {
	border-style: none;
	border-color: #fff;
	background-color: #fff;
	color: #3079b5;
}

.div-block-15 {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.div-block-16 {
	display: flex;
	padding-top: 20px;
	justify-content: space-between;
}

.link-block-2 {
	width: 25%;
	height: auto;
	margin-top: 5px;
	margin-right: 20px;
}

.link-block-2.logo-size.logo-size-mob {
	width: 30%;
}

.div-block-17 {
	display: flex;
	width: auto;
	justify-content: flex-start;
	align-items: center;
}

.div-block-18 {
	display: flex;
	width: 25%;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
	align-items: center;
}

.image-8 {
	width: 35%;
}

.image-9 {
	width: 30%;
	margin-left: 20px;
}

.text-block-8 {
	width: 600px;
	margin-left: 0px;
	font-family: Montserrat, sans-serif;
	font-size: 15px;
	line-height: 20px;
	color: #333;
	font-weight: 400;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.gray {
	background-image: url('/20181016082423/assets/images/globalreach_grey.svg');
	background-size: 130px 29px;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-gray {
	color: #797676;
}

.image-10 {
	width: auto;
	max-width: 100%;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-signoff-link {
	transition: color 300ms ease;
	color: #333;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #333;
	font-size: 0.9rem;
}

.footer-copyright h1 {
	display: inline;
	margin: 0px;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
}

.column-3 {
	display: flex;
	height: 100%;
	padding-right: 0px;
	padding-left: 0px;
	justify-content: flex-start;
	align-items: center;
	font-family: Montserrat, sans-serif;
}

.column-2 {
	display: flex;
	padding-right: 0px;
	justify-content: flex-end;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid rgba(78, 77, 93, 0.5);
	color: #333;
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.columns {
	display: flex;
	height: auto;
	margin-top: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #a0a0a8;
}

.slide-butotn {
	padding: 0px;
	background-color: transparent;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 600;
}

.slide-butotn:hover {
	padding-right: 0px;
}

.slider-arrow {
	position: static;
	overflow: hidden;
	width: auto;
	height: auto;
}

.slider-arrow:hover {
	width: auto;
	height: auto;
}

.div-block-19 {
	display: flex;
	width: 100%;
	align-items: flex-end;
}

.news-see-all {
	display: flex;
	overflow: hidden;
	width: auto;
	height: 45px;
	margin-bottom: 10px;
	margin-left: 20px;
	align-items: center;
}

.news-button {
	padding: 0px;
	background-color: transparent;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 600;
}

.news-button:hover {
	padding-right: 0px;
}

.news-arrow {
	width: 0px;
	margin-left: 10px;
}

.nav-dropdown-row {
	min-width: 500px;
	border-top: 3px solid #4f95d0;
}

.nav-drop-list {
	border-bottom: 3px solid #ad3b48;
	background-color: #ede8e2;
	background-image: url('/20181016082423/assets/images/exclusive-paper.png');
	box-shadow: 0 3px 8px -3px rgba(95, 87, 76, 0.3);
}

.nav-drop-list.w--open {
	border-bottom-color: #fff;
	background-color: #fff;
	background-image: none;
}

.nav-drop-list-link {
	padding: 0.625rem;
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	border-bottom: 1px solid transparent;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
	text-decoration: none;
}

.nav-drop-list-link:hover {
	background-color: rgba(47, 108, 160, 0.2);
}

.nav-drop-list-link.w--current {
	color: #4f95d0;
}

.nav-mega-menu-column-title {
	margin-bottom: 1rem;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border: 1px none #000;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.nav-mega-menu-list {
	margin-bottom: 0px;
}

.nav-dropdown-column-content-wrap {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

.nav-drop.hidden-mobile {
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.dropdown-toggle-2 {
	padding: 0px;
}

.div-block-20 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.testimonials-see-all {
	display: flex;
	overflow: hidden;
	width: auto;
	height: 45px;
	margin-bottom: 0px;
	margin-left: 20px;
	align-items: center;
	color: #fff;
}

.testimonial-arrow {
	width: 0px;
	margin-left: 10px;
}

.testimonial-button {
	padding: 0px;
	background-color: transparent;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 600;
}

.testimonial-button:hover {
	padding-right: 0px;
}

.footer-nav {
	display: flex;
	width: 100%;
	max-width: 1500px;
	min-width: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-left: 0px;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	border-radius: 3px;
	list-style-type: none;
	-o-object-fit: contain;
	object-fit: contain;
}

.footer-nav-li {
	display: flex;
	width: auto;
	justify-content: space-around;
	align-self: center;
	text-align: center;
}

.footer-nav-a {
	display: block;
	padding: 14px 10px;
	border-radius: 3px;
	background-color: #155d40;
	color: #fff;
	font-size: 15px;
	line-height: 22px;
	font-weight: 700;
	text-decoration: none;
}

.footer-nav-a:hover {
	background-color: #1a8a4f;
}

.footer-nav-a.blue {
	width: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 10px;
	padding-left: 10px;
	border-radius: 50px;
	background-color: transparent;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
}

.footer-nav-a.blue:hover {
	background-color: rgba(255, 255, 255, 0.06);
}

.footer-nav-a.orange {
	width: auto;
	margin-right: 5px;
	margin-left: 5px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 50px;
	background-color: transparent;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
}

.footer-nav-a.orange:hover {
	background-color: rgba(255, 255, 255, 0.06);
}

.footer-nav-a.purple {
	width: auto;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 50px;
	background-color: transparent;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	font-size: 15px;
}

.footer-nav-a.purple:hover {
	background-color: rgba(255, 255, 255, 0.06);
}

.facebook-icon {
	margin-top: -2px;
	margin-right: 10px;
	margin-bottom: 0px;
}

.facebook-label {
	display: inline-block;
	margin-bottom: 0px;
	font-size: 14px;
}

.section-container {
	display: flex;
	max-width: none;
	padding: 20px 100px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #213064;
}

.nav-menu-2 {
	position: fixed;
	margin-top: 98px;
}

.div-block-21 {
	overflow: hidden;
	background-color: #fff;
	height: auto;
}

.nav-menu-3 {
	position: static;
	margin-top: 0px;
	background-color: #213064;
}

.account-button-mobile {
	display: flex;
	height: auto;
	padding: 9px 25px 9px 40px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #84d5f7;
	border-radius: 100px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.account-button-mobile:hover {
	background-color: #2f6ca0;
}

.div-block-22 {
	display: none;
}

.mobile-search {
	display: none;
	overflow: hidden;
	width: 38px;
	height: 38px;
	margin: 10px 20px;
	align-items: center;
	opacity: 1;
}

.button-copy {
	display: none;
	height: auto;
	padding: 9px 25px 9px 40px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #84d5f7;
	border-radius: 100px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.button-copy:hover {
	background-color: #2f6ca0;
}

.slider-learn-more {
	display: flex;
	overflow: hidden;
	width: auto;
	height: 45px;
	margin-bottom: 20px;
	align-items: center;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.ordered-list {
	padding-left: 20px;
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.heading-2-link {
	height: auto;
	border: 1px none #000;
	transition: all 300ms ease;
	color: #213064;
	font-size: 32px;
	line-height: 25px;
	text-decoration: underline;
	cursor: pointer;
}

.heading-2-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 6px;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.button-5 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	justify-content: center;
	align-items: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-style: solid;
	border-width: 2px;
	border-color: #4f95d0;
	border-radius: 50px;
	background-color: #4f95d0;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

.button-5:hover {
	border-style: solid;
	border-width: 2px;
	border-color: #4f95d0;
	background-color: #fff;
	color: #213064;
}

.button-5.secondary {
	border-color: #213064;
	background-color: #213064;
	transition: all 200ms ease;
	color: #fff;
	text-align: center;
	cursor: pointer;
}

.button-5.secondary:hover {
	border-color: #213064;
	background-color: #fff;
	background-image: none;
	opacity: 1;
	color: #213064;
}

.button-5.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button-5.tertiary:hover {
	background-color: #f1f1f1;
	transform: none;
	color: #000;
}

.left-nav-wrapper {
	height: auto;
	margin-top: -3px;
	margin-right: 1rem;
	margin-left: 0rem;
	padding-right: 1rem;
	padding-left: 0.5rem;
	border-right: 1px none rgba(228, 19, 86, 0.59);
}

.h6-link {
	height: auto;
	border: 1px none #000;
	transition: all 450ms ease;
	color: #213064;
	font-size: 12px;
	line-height: 18px;
	text-decoration: underline;
	cursor: pointer;
}

.h6-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 6px;
}

.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-width: 9px;
	border-left-color: #213064;
	background-color: #e7e7e7;
	color: #4a4a4a;
	font-size: 1.35rem;
	line-height: 2rem;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0vw;
	padding-left: 0vw;
	flex-direction: row;
	justify-content: center;
	background-color: #fff;
}

.main-content-outer-wrap {
	display: flex;
	width: 100%;
	align-items: flex-start;
}

.h5-link {
	height: auto;
	border: 1px none #000;
	transition: all 450ms ease;
	color: #213064;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.h5-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 20px;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: 250px;
	min-height: 500px;
	min-width: 240px;
	margin-top: 0px;
	margin-right: 3vw;
	padding-top: 1rem;
	padding-right: 0.75px;
	padding-bottom: 4rem;
	float: left;
	flex: 0 0 auto;
	border-right: 3px none #e41356;
	background-color: #e7e7e7;
}

.breadcrumb-link {
	border-bottom: 1px solid rgba(64, 64, 64, 0.46) !important;
	transition: all 450ms ease;
	color: #404040 !important;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
	font-weight: 400 !important;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent !important;
	color: #404040;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	color: #213064;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.main-content-inner-wrap {
	display: flex;
	padding-top: 1rem;
	width: 100%;
	min-height: 550px;
	font-family: Montserrat, sans-serif;
}

.text-link {
	border-bottom: 1px solid #2f6ca0;
	transition: all 450ms ease;
	color: #696b6d;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #213064;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
	color: #4a4a4a;
	font-weight: 500;
}

.h3-link {
	height: auto;
	border: 1px none #000;
	transition: all 450ms ease;
	color: #213064;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline;
	cursor: pointer;
}

.h3-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 6px;
	text-decoration: underline;
}

.h4-link {
	height: auto;
	border: 1px none #000;
	transition: all 450ms ease;
	color: #213064;
	font-size: 18px;
	line-height: 24px;
	text-decoration: underline;
	cursor: pointer;
}

.h4-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #2f6ca0;
	line-height: 24px;
}

.left-nav-link-3 {
	display: block;
	width: 218px;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	border-left: 2px none transparent;
	border-radius: 0px;
	transition: all 300ms ease;
	color: #4a4a4a;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3:hover {
	width: 218px;
	padding-right: 0rem;
	padding-left: 0.63rem;
	border-left-style: none;
	border-left-color: #e41356;
	background-color: rgba(255, 255, 255, 0.47);
	color: #213064;
}

.left-nav-link-3.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-color: rgba(255, 255, 255, 0.47);
	transition-duration: 450ms;
	color: #213064;
	text-transform: none;
}

.left-nav-link-3.w--current:hover {
	background-color: rgba(255, 255, 255, 0.47);
	transform: none;
}

.left-nav-link-3.grandchild-link {
	width: 206px;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	border-radius: 0px;
	transition: all 300ms ease;
	color: #4a4a4a;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	width: 206px;
	border-left-color: #e41356;
	background-color: rgba(255, 255, 255, 0.47);
	color: #213064;
}

.left-nav-link-3.grandchild-link.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-image: none;
	color: #213064;
	font-weight: 600;
}

.left-nav-link-3.grandchild-link.w--current:hover {
	background-color: rgba(255, 255, 255, 0.47);
}

.left-nav-link-3.greatgrandchild-link {
	width: 193px;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	transition: all 300ms ease;
	color: #4a4a4a;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	width: 192px;
	border-left-color: #e41356;
	box-shadow: none;
	color: #213064;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	border-left-color: #c2d82e;
	background-image: none;
	transition-duration: 450ms;
	color: #213064;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 0rem;
	flex-direction: row;
}

.textlink-middleout {
	border-bottom: 1px solid #213064;
	transition: all 450ms ease;
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
}

.textlink-middleout:hover {
	border-bottom-color: transparent;
	box-shadow: none;
	color: #213064;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #404040;
	font-size: 1rem;
}

.container-3 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.inside-page-container {
	max-width: 1200px;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
	color: #fff;
	margin-top: 0px;
}

.left-nav-section-title-link {
	display: block;
	margin-bottom: 5px;
	padding: 0.25rem 0px 0.4rem 10px;
	border: 1px none #000 !important;
	background-color: transparent;
	color: #213064 !important;
	font-size: 1.7rem !important;
	line-height: 23px !important;
	font-weight: 700;
	text-decoration: none !important;
}

.left-nav-section-title-link:hover {
	color: #213064 !important;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #4a4a4a;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	height: auto;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	border-top: 8px none #629edc;
}

.paragraph-3 {
	color: #4a4a4a;
	font-size: 16px;
	line-height: 22px;
}

.paragraph-4 {
	color: #4a4a4a;
	font-size: 16px;
	line-height: 22px;
}

.paragraph-5 {
	color: #4a4a4a;
	font-size: 23px;
	line-height: 22px;
	font-weight: 600;
}

.list {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-6 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-7 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-8 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-9 {
	color: #4a4a4a;
	line-height: 22px;
}

.paragraph-10 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-11 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-12 {
	color: #4a4a4a;
	line-height: 22px;
	font-weight: 500;
}

.paragraph-13 {
	color: #4a4a4a;
	line-height: 22px;
}

.heading-9 {
	color: #4a4a4a;
	font-size: 26px;
	font-weight: 600;
}

.heading-10 {
	color: #4a4a4a;
}

.section-3 {
	height: 210px;
	background-image: url('/20181016082423/assets/images/freedom-wdm-1200x250.jpg');
	background-position: 50% 29%;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-3.no-banner {
	background: none !important;
	height: 20px;
	margin: 0px;
}

.page-title {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0%;
	padding: 0px 0px 10px;
	border-radius: 20px;
	background-color: transparent;
	color: #424242;
	font-size: 40px;
	line-height: 55px;
	font-weight: 700;
	text-transform: none;
}

.footer-nav-social {
	display: flex;
	width: 500px;
	justify-content: space-around;
	flex-wrap: nowrap;
	align-self: center;
	text-align: center;
}

.link {
	color: #404040;
	text-decoration: none;
}

.right-side-col {
	position: relative;
	width: 20%;
	max-width: 250px;
	min-width: 160px;
	margin-top: 0px;
	margin-left: 3vw;
	padding-top: 0rem;
	padding-left: 10px;
	flex: 0 0 auto;
	background-color: transparent;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list-item-3 {
	margin-bottom: 10px;
}

.h2-right-column-title {
	margin-top: 0px;
	margin-left: -10px;
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #213064;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.h4-right-column-title {
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #4f95d0;
}

.h3-right-column-title {
	margin-left: -10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	background-color: transparent;
	color: #213064;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.right-column-link {
	margin-left: 10px;
	padding-right: 0px;
	padding-left: 0px;
	border-bottom: 1px solid #404040;
	background-color: transparent;
	transition: all 300ms ease;
	color: #404040;
	font-weight: 600;
	text-decoration: none;
}

.right-column-link:hover {
	border-bottom: 1px none transparent;
	background-color: transparent;
	color: #213064;
	font-weight: 600;
}

.paragraph-14 {
	margin-top: 10px;
	color: #404040;
	font-style: normal;
}

.login-dropdown {
	width: 120px;
	height: 30px;
	margin-top: 4px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 3px 10px;
	float: left;
	border-style: none;
	background-color: #fff;
}

.login-dropdown.login-field {
	margin-top: 0px;
	margin-right: 5px;
	background-color: #e8f0fe;
}

.login-dropdown.login-field {
	margin-top: 0px;
	margin-right: 5px;
	background-color: #e8f0fe;
}

.link-2 {
	display: block;
	width: auto;
	margin-top: 0px;
	padding-top: 0px;
	float: left;
	color: #616265;
	font-size: 12px;
	line-height: 24px;
	font-weight: 700;
	text-align: right;
	text-decoration: none;
}

.link-2:hover {
	color: #4f95d0;
}

.text-block-9 {
	display: block;
	width: auto;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 10px;
	padding-left: 10px;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
}

.div-block-24 {
	display: flex;
	margin-top: 5px;
	justify-content: center;
	align-items: center;
}

.form-block {
	margin-top: 4px;
	opacity: 0;
	transform: translate(0px, -105px);
}

.image-11 {
	width: 458px;
}

.div-block-25 {
	display: flex;
	min-height: 600px;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/20181016082423/assets/images/Happy-Customers.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, auto;
}

.login-link {
	display: inline;
	padding-right: 20px;
	color: #fff;
	cursor: pointer;
}

.online-banking-module {
	display: block;
	max-width: 250px;
	padding: 16px;
	flex-direction: column;
	order: 1;
	background-color: #941114;
}

.form-block-2 {
	margin-bottom: 5px;
}

.login-link-copy {
	display: inline;
	padding-right: 0px;
	flex-direction: row;
	color: #fff;
	cursor: pointer;
}

.form {
	display: flex;
	align-items: center;
}

.heading-11 {
	margin-top: 0px;
	margin-bottom: 5px;
	color: #fff;
	font-weight: 600;
	text-align: left;
	text-transform: uppercase;
}

.text-field {
	margin-bottom: 0px;
}

.submit-button {
	background-color: #001e45;
	font-weight: 600;
}

.text-block-10 {
	width: 50%;
	margin-top: 140px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 40px;
	line-height: 44px;
	font-weight: 700;
	text-align: center;
	text-shadow: none;
}

.account-login-wrapper {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 10;
	display: flex;
	width: 100%;
	height: 100%;
	max-height: 375px;
	max-width: 400px;
	margin-top: 100px;
	margin-right: 60px;
	margin-left: 40px;
	padding-right: 2rem;
	padding-left: 2rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 0px;
	background-color: #213064;
	background-image: linear-gradient(180deg, rgba(33, 48, 100, 0.78), rgba(33, 48, 100, 0.78)), url('/20181016082423/assets/images/workingonlaptop.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	opacity: 1;
}

.account-login-wrapper.inside {
	position: static;
	width: 91%;
	max-width: 100%;
	margin-left: 10px;
	padding-right: 0.5rem;
	padding-left: 0.63rem;
}

.account-login-wrapper.inside-pages {
	position: static;
	width: 96%;
	max-height: 330px;
	max-width: none;
	margin-top: 20px;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.slide-link {
	padding: 0px;
	background-color: transparent;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 600;
}

.slide-link:hover {
	padding-right: 0px;
}

.slide-link.enroll {
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	border-bottom: 2px solid #fff;
	text-align: left;
}

.slide-link.enroll:hover {
	border-bottom-color: transparent;
	opacity: 0.65;
}

.slide-link.enroll.padding-10 {
	padding-top: 10px;
}

.slide-link.enroll.hidden {
	display: none;
}

.slide-link.enroll {
	margin-right: 0px;
	margin-left: 0px;
	border-bottom: 2px solid #fff;
}

.slide-link.enroll:hover {
	border-bottom-color: transparent;
	opacity: 0.65;
}

.div-block-26 {
	display: flex;
	width: 100%;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	flex-direction: column;
	align-items: flex-start;
}

.text-field-2 {
	border: 1px none #000;
	border-radius: 5px;
}

.form-2 {
	font-family: Montserrat, sans-serif;
}

.login-button {
	display: flex;
	height: auto;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0px;
	padding: 9px 40px;
	align-items: center;
	border-style: solid;
	border-width: 2px;
	border-color: #4f95d0;
	border-radius: 100px;
	background-color: #4f95d0;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	line-height: 20px;
	font-weight: 600;
}

.login-button:hover {
	border-style: solid;
	border-color: #fff;
	background-color: #fff;
	color: #213064;
}

.online-banking-form {
	width: 100%;
	margin-bottom: 0px;
}

.online-banking-heading {
	display: block;
	margin-top: 0px;
	margin-bottom: 0px;
	align-items: flex-end;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 36px;
	font-weight: 800;
	text-align: left;
	text-transform: none;
	text-shadow: none;
}

.online-banking-heading.inside {
	font-size: 16px;
}

.image-12 {
	height: 63px;
}

.nav-menu-4 {
	position: static;
	margin-top: 0px;
	background-color: #e7e7e7;
}

.button-copy-2 {
	display: none;
	height: auto;
	padding: 9px 25px 9px 40px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #84d5f7;
	border-radius: 100px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.button-copy-2:hover {
	background-color: #2f6ca0;
}

.search-button-2 {
	width: 38px;
	height: 38px;
	margin-right: 0px;
	margin-left: 0px;
	border: 2px solid transparent;
	border-radius: 20px;
	background-color: #afeef9;
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
}

.search-button-2:hover {
	border-style: solid;
	border-width: 2px;
	border-color: #afeef9;
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/search_black.svg');
	background-size: 15px;
	background-repeat: no-repeat;
}

.link-3 {
	font-family: Montserrat, sans-serif;
	display: block;
	width: auto;
	margin-top: 0px;
	padding-top: 0px;
	float: left;
	color: #616265;
	font-size: 12px;
	line-height: 24px;
	font-weight: 600;
	text-align: right;
	text-decoration: none;
}

.link-3:hover {
	color: #8b8b8b;
}

.nav-drop-list-link-2 {
	padding: 0.625rem;
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	border-bottom: 1px solid transparent;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
	text-decoration: none;
}

.nav-drop-list-link-2:hover {
	background-color: rgba(33, 48, 100, 0.11);
}

.nav-drop-list-link-2.w--current {
	color: #4f95d0;
}

.nav-menu-5 {
	position: static;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 200;
	display: flex;
	width: 100%;
	margin-top: 0px;
	justify-content: space-around;
	align-items: center;
	background-color: #213064;
}

.login-field-2 {
	width: 150px;
	height: 30px;
	margin-right: 5px;
	margin-bottom: 0px;
	float: left;
	border: 1px none #000;
	border-radius: 3px;
	background-color: #e8f0fe;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

.login-div-2 {
	position: absolute;
	top: 0px;
	right: 0px;
	display: block;
	overflow: hidden;
	margin-top: 37.4px;
	margin-right: 20px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: transparent;
	opacity: 1;
	color: #505050;
}

.nav-dropdown-row-2 {
	min-width: 500px;
	border-top: 3px solid #4f95d0;
}

.nav-mega-menu-column-title-2 {
	margin-bottom: 1rem;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border: 1px none #000;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.nav-drop-list-2 {
	border-bottom: 3px solid #ad3b48;
	background-color: #ede8e2;
	background-image: url('/20181016082423/assets/images/exclusive-paper.png');
	box-shadow: 0 3px 8px -3px rgba(95, 87, 76, 0.3);
}

.nav-drop-list-2.w--open {
	border-bottom-color: #fff;
	background-color: #fff;
	background-image: none;
}

.nav-link-row-2 {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	display: block;
	justify-content: space-around;
	align-items: center;
}

.navbar-2 {
	position: static;
	display: flex;
	padding-top: 0px;
	padding-bottom: 0px;
	flex-direction: column;
	background-color: #fff;
}

.nav-link-2 {
	margin-right: 0px;
	margin-left: 0px;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 18px;
	line-height: 0px;
	font-weight: 600;
}

.nav-link-2:hover {
	color: #eee9ca;
}

.nav-link-2:active {
	flex: 0 auto;
}

.account-button-mobile-2 {
	display: flex;
	height: auto;
	padding: 9px 25px 9px 40px;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #84d5f7;
	border-radius: 100px;
	background-color: #4f95d0;
	background-image: url('/20181016082423/assets/images/lock.svg');
	background-position: 9% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	line-height: 20px;
	font-weight: 600;
}

.account-button-mobile-2:hover {
	background-color: #2f6ca0;
}

.select-field {
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	float: left;
	border-radius: 5px;
	font-family: Lato, sans-serif;
}

.logo-overlay {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: -1;
	width: 50%;
	background-image: url('/20181016082423/assets/images/purposebank-logo-gray.svg');
	background-position: 0% 50%;
	background-size: 650px;
	background-repeat: no-repeat;
	opacity: 0.45;
}

.account-login-cb {
	position: relative;
	display: flex;
	overflow: visible;
	width: 50%;
	height: 70vh;
	margin-top: 0px;
	padding: 0rem 100px;
	justify-content: flex-end;
	align-items: center;
	background-color: transparent;
}

.blue-button-2 {
	display: flex;
	height: auto;
	margin-top: 20px;
	margin-bottom: 40px;
	padding: 9px 25px;
	align-items: center;
	border-style: solid;
	border-width: 2px;
	border-color: #afeef9;
	border-radius: 100px;
	background-color: #afeef9;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	line-height: 20px;
	font-weight: 600;
}

.blue-button-2:hover {
	border-style: solid;
	border-color: #afeef9;
	background-color: #fff;
	color: #afeef9;
}

.right-slideshow-arrow {
	left: 185px;
	top: auto;
	right: 0%;
	bottom: 38px;
	display: flex;
	width: 20px;
	height: 50px;
	margin-right: 0px;
	margin-left: 0px;
	justify-content: center;
	align-items: center;
	transition: opacity 200ms ease;
	color: #fff;
	font-weight: 700;
}

.right-slideshow-arrow:hover {
	color: #09b485;
}

.slide-5 {
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.div-block-27 {
	display: flex;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	align-items: center;
}

.account-login-wrapper-2 {
	display: flex;
	width: 100%;
	height: 100%;
	max-height: 375px;
	max-width: 525px;
	padding-right: 2rem;
	padding-left: 2rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-radius: 20px;
	background-color: #006f51;
	background-image: linear-gradient(180deg, rgba(0, 111, 81, 0.9), rgba(0, 111, 81, 0.9)), url('/20181016082423/assets/images/workingonlaptop.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	opacity: 1;
}

.paragraph {
	max-width: 625px;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: left;
}

.paragraph.white {
	color: #fff;
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
}

.bg-color-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
}

.slider-5 {
	position: relative;
	margin-top: 0px;
}

.login-button-2 {
	display: flex;
	height: auto;
	margin-top: 0px;
	margin-right: 1rem;
	margin-bottom: 0px;
	padding: 9px 25px;
	align-items: center;
	border-style: solid;
	border-width: 2px;
	border-color: #afeef9;
	border-radius: 100px;
	background-color: #afeef9;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	line-height: 20px;
	font-weight: 600;
}

.login-button-2:hover {
	border-style: solid;
	border-color: #afeef9;
	background-color: #fff;
	color: #afeef9;
}

.left-slideshow-arrow {
	left: 100px;
	top: auto;
	right: auto;
	bottom: 38px;
	display: flex;
	width: 20px;
	height: 50px;
	justify-content: flex-start;
	align-items: center;
	color: #fff;
	font-weight: 700;
}

.left-slideshow-arrow:hover {
	color: #09b485;
}

.slider-6 {
	width: 50%;
	height: 70vh;
	margin-top: 0px;
	background-color: transparent;
}

.online-banking-form-2 {
	width: 100%;
	margin-bottom: 0px;
}

.slideshow-wrapper {
	display: flex;
	height: 100%;
	padding-right: 100px;
	padding-left: 100px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.heading-12 {
	margin-top: 0px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 50px;
	line-height: 50px;
	font-weight: 800;
	text-transform: none;
	text-shadow: none;
}

.heading-12.white {
	color: #fff;
	text-shadow: -1px 1px 8px rgba(0, 0, 0, 0.65);
}

.slide-6 {
	background-color: transparent;
	background-position: 50% 50%;
	background-size: cover;
}

.slide-nav-4 {
	bottom: 40px;
	display: flex;
	padding-left: 125px;
	border-style: none;
	border-width: 1px;
	border-color: #fff;
	color: #fff;
	font-size: 0.8rem;
}

.icon-2 {
	display: flex;
	font-size: 15px;
}

.online-banking-heading-2 {
	margin-top: 0px;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 32px;
	line-height: 36px;
	font-weight: 800;
	text-transform: none;
	text-shadow: none;
}

.slide-7 {
	background-color: #fff;
	background-position: 0px 0px;
	background-size: auto;
}

.italic-text {
	font-style: normal;
}

.smaller-text-size {
	font-size: 14px;
}

.search-button-3 {
	background-color: #213064;
}

.submit-button-2 {
	background-color: #213064;
}

.text-block-12 {
	color: #4a4a4a;
	font-weight: 500;
}

.text-block-13 {
	color: #4a4a4a;
	font-weight: 500;
}

.text-block-14 {
	color: #4a4a4a;
	font-weight: 500;
}

.heading-13 {
	color: #4a4a4a;
}

.heading-14 {
	color: #4a4a4a;
}

.heading-15 {
	color: #4a4a4a;
}

#loginFormContainerHomepage .login-field,
#loginFormContainerHomepage .login-dropdown {
	background-color: #fff;
	width: 100%;
	height: auto;
	float: none;
	margin-bottom: 10px;
	padding: 8px 12px;
}

#loginFormContainerHomepage .button-2.login-button {
	padding: 9px 25px;
	height: auto;
	float: none;
	border-radius: 100px;
	margin-top: 1.1rem;
}

#loginFormContainerHomepage .link-2 {
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
}

#loginFormContainerHomepage .text-block-9 {
	font-size: 14px;
	line-height: 24px;
	color: #fff;
}

#loginFormContainerHomepage .div-block-24 {
	margin-top: 1.5rem;
}

#loginFormContainerHeader .login-form {
	float: left;
}

#loginFormContainerHeader.form-block {
	float: left;
}

#loginFormContainerHeader .login-form-header {
	display: inline;
	float: left;
}

#loginFormContainerHeader .login-dropdown-container {
	display: block;
}

@media screen and (max-width: 991px) {
	.section-3.no-banner {
		background: none !important;
		height: 0;
	}

	.slider {
		z-index: 410;
		margin-top: 102px;
	}

	.navigation {
		position: static;
		z-index: 999;
		margin-top: 101px;
		margin-bottom: -103px;
	}

	.slider-2 {
		min-height: 500px;
		display: flex;
		height: auto;
		justify-content: center;
		align-items: flex-end;
	}

	.link-block {
		width: 30%;
		height: 200px;
	}

	.icon-row {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.top-nav {
		position: relative;
		padding-right: 61px;
		padding-left: 40px;
	}

	.navbar {
		position: static;
	}

	.brand {
		max-width: 245px;
	}

	.nav-link {
		display: block;
		width: auto;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.nav-link:hover {
		background-color: rgba(47, 108, 160, 0.2);
		color: #404040;
	}

	.nav-link-row {
		position: relative;
		z-index: 900;
		height: 23px;
		margin-top: -26px;
	}

	.nav-menu {
		background-color: #fff;
	}

	.left-arrow {
		z-index: 30;
	}

	.right-arrow {
		z-index: 30;
	}

	.slide-nav {
		z-index: 20;
	}

	.button {
		margin-right: 10px;
	}

	.slide {
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.login-field {
		width: 180px;
		height: 40px;
	}

	.login-field-label {
		font-size: 16px;
		line-height: 40px;
	}

	.login-div {
		right: 84px;
		height: 107px;
		margin-top: 0px;
		margin-right: -60px;
		margin-left: 0px;
		border-radius: 5px;
	}

	.button-2.login-button {
		height: 40px;
		padding-top: 10px;
	}

	.button-2.login-button {
		height: 40px;
		padding-top: 0;
		background-color: #4f95d0;
		color: #fff;
	}

	.button-2.login-button:hover {
		border-color: #4f95d0;
		background-color: #fff;
		color: #4f95d0;
	}

	.news-section {
		padding-right: 40px;
		padding-left: 40px;
	}

	.div-block-8 {
		position: relative;
		z-index: 1000;
	}

	.callout-image {
		height: 400px;
	}

	.slider-graphic {
		padding-right: 20px;
		padding-bottom: 20px;
		padding-left: 40px;
		grid-column-gap: 68px;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.slider-graphic.slider-2 {
		display: -ms-grid;
		display: grid;
		height: 100%;
		justify-content: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 64px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		background-position: 30% 50%, 0px 0px;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
		background-clip: border-box;
		-webkit-text-fill-color: inherit;
	}

	.slide-2 {
		background-position: 0px 0px, 28% 28%;
		background-size: auto, auto;
		background-repeat: repeat, repeat;
	}

	.heading-3 {
		font-size: 45px;
		line-height: 50px;
	}

	.text-block {
		font-size: 20px;
		line-height: 25px;
	}

	.heading-4 {
		font-size: 32px;
	}

	.text-block-5 {
		font-size: 16px;
		line-height: 22px;
	}

	.testimonial {
		height: 500px;
		padding-bottom: 40px;
		padding-left: 10px;
	}

	.footer-container {
		padding-right: 40px;
		padding-left: 40px;
	}

	.paragraph-2 {
		max-width: 550px;
		font-size: 15px;
		line-height: 20px;
	}

	.heading-6 {
		font-size: 40px;
	}

	.heading-7 {
		background-size: 43px;
		font-size: 40px;
		line-height: 44px;
	}

	.div-block-14 {
		width: 80%;
	}

	.slider-4 {
		width: 612px;
	}

	.slide-3 {
		padding-left: 30px;
	}

	.contact-us {
		padding-right: 40px;
		padding-left: 40px;
	}

	.text-block-7 {
		text-align: center;
	}

	.link-block-2.logo-size {
		width: 60%;
	}

	.link-block-2.logo-size.logo-size-mob {
		width: 55%;
	}

	.image-8 {
		width: 70%;
	}

	.image-9 {
		width: 40%;
		max-width: 100%;
	}

	.text-block-8 {
		width: 400px;
	}

	.image-10 {
		max-width: 100%;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.column-3 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.column-2 {
		padding-right: 0px;
		flex-direction: column;
		align-items: flex-end;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.nav-dropdown-row {
		display: none;
	}

	.nav-drop {
		float: none;
	}

	.nav-drop.hidden-mobile {
		display: block;
	}

	.testimonials-see-all {
		margin-left: 20px;
	}

	.menu-button {
		margin-top: -53px;
		margin-bottom: 16px;
		margin-left: 0px;
		background-color: transparent;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.footer-nav {
		width: 70%;
		max-width: none;
		padding-top: 20px;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		align-items: flex-end;
		text-align: center;
		margin: 0 auto;
	}

	.footer-nav-li {
		width: auto;
		margin-right: 0px;
		margin-bottom: 0px;
		flex-direction: row;
		justify-content: space-around;
		align-items: stretch;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.footer-nav-a.blue {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		font-size: 13px;
	}

	.footer-nav-a.orange {
		margin-right: 20px;
		margin-left: 20px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.section-container {
		margin-top: 0px;
		margin-bottom: 0px;
		padding: 20px 10px;
		flex-direction: column;
	}

	.div-block-21 {
		position: static;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 999;
		background-color: #fff;
	}

	.account-button-mobile {
		margin-right: 10px;
	}

	.div-block-22 {
		display: none;
	}

	.icon-7 {
		color: #4f95d0;
	}

	.mobile-search {
		display: none;
	}

	.button-copy {
		display: none;
		margin-right: 10px;
	}

	.main-content-outer-wrap {
		flex-direction: column;
	}

	.left-nav-col {
		display: none;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.main-content-inner-wrap {
		display: block;
		flex-direction: column;
		min-height: 400px;
		padding-top: 0px;
	}

	.main-content {
		width: 100%;
		display: inline-block;
		padding-top: 0;
	}

	.container-3 {
		max-width: 768px;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-nav-social {
		display: inline-block;
		width: 25%;
		margin-right: 20px;
		margin-bottom: 20px;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
	}

	.login-dropdown.login-field {
		width: 152px;
	}

	.form-block {
		margin-top: 3px;
		padding-bottom: 20px;
		opacity: 0;
	}

	.div-block-25 {
		min-height: 400px;
		background-image: linear-gradient(180deg, transparent 50%, #fff), url('/20181016082423/assets/images/Happy-Customers-tablet.jpg');
		background-position: 0px 0px, 0px 0px;
		background-size: auto, auto;
	}

	.login-link {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.online-banking-module {
		display: none;
		margin-left: 10px;
		align-self: flex-start;
	}

	.login-link-copy {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.text-block-10 {
		width: 86%;
		font-size: 32px;
		line-height: 38px;
	}

	.account-login-wrapper {
		max-height: 350px;
		max-width: 350px;
		margin: 70px 20px 0px 0px;
	}

	.slide-link.enroll.padding-10 {
		padding-top: 5px;
	}

	.div-block-26 {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		flex-wrap: wrap;
	}

	.login-button {
		margin-bottom: 1rem;
	}

	.online-banking-heading {
		font-size: 24px;
		line-height: 35px;
		text-align: left;
	}

	.button-copy-2 {
		display: none;
		margin-right: 10px;
	}

	.menu-button-2 {
		margin-top: -53px;
		margin-bottom: 16px;
		margin-left: 0px;
		background-color: transparent;
		color: #231f20;
		display: block !important;
	}

	.menu-button-2.w--open {
		background-color: transparent;
	}

	.nav-menu-5 {
		background-color: #fff;
	}

	.login-field-2 {
		width: 180px;
		height: 40px;
	}

	.login-div-2 {
		right: 84px;
		height: 151px;
		margin-top: -2px;
		margin-right: -60px;
		margin-left: 20px;
		border-radius: 5px;
	}

	.nav-dropdown-row-2 {
		display: none;
	}

	.nav-link-row-2 {
		position: relative;
		z-index: 900;
		height: 23px;
		margin-top: -26px;
	}

	.navbar-2 {
		position: static;
	}

	.nav-link-2 {
		display: block;
		width: auto;
		padding-top: 30px;
		padding-bottom: 30px;
		color: #213064;
	}

	.nav-link-2:hover {
		background-color: rgba(33, 48, 100, 0.14);
		color: #404040;
	}

	.account-button-mobile-2 {
		margin-right: 10px;
	}

	.icon-8 {
		color: #333;
	}

	.logo-overlay {
		background-size: 450px;
	}

	.account-login-cb {
		margin-top: 0px;
		padding-right: 40px;
		padding-left: 40px;
	}

	.right-slideshow-arrow {
		left: 120px;
	}

	.div-block-27 {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		flex-wrap: wrap;
	}

	.paragraph {
		max-width: none;
		font-size: 0.95rem;
	}

	.slider-5 {
		z-index: 410;
		margin-top: 98px;
	}

	.login-button-2 {
		margin-bottom: 1rem;
	}

	.left-slideshow-arrow {
		left: 40px;
	}

	.slider-6 {
		margin-top: 0px;
	}

	.slideshow-wrapper {
		padding-right: 40px;
		padding-left: 40px;
	}

	.heading-12 {
		font-size: 40px;
		line-height: 40px;
	}

	.slide-6 {
		background-position: 50% 50%;
	}

	.slide-nav-4 {
		padding-left: 60px;
	}

	.online-banking-heading-2 {
		font-size: 30px;
		line-height: 35px;
	}

	.slide-7 {
		background-position: 50% 50%;
	}

	.div-block-3 {
		height: 20vw;
	}

	.image-3 {
		height: 20vw;
	}

	.div-block-4 {
		height: 20vw;
	}

	.div-block-5 {
		height: 20vw;
	}
}

@media screen and (max-width: 767px) {
	.slider {
		position: static;
		z-index: 445;
		margin-top: 0px;
	}

	.navigation {
		z-index: 999;
		margin-top: 86px;
	}

	.slider-2 {
		position: relative;
		min-height: 450px;
		height: auto;
	}

	.link-block {
		width: 50%;
	}

	.icon-row {
		position: relative;
		z-index: 500;
		background-color: #fff;
	}

	.top-nav {
		position: relative;
		padding-right: 10px;
		padding-left: 10px;
	}

	.brand {
		z-index: 460;
		margin-left: 10px;
		padding-left: 0px;
	}

	.nav-link {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.nav-link:hover {
		background-color: rgba(47, 108, 160, 0.2);
		color: #404040;
	}

	.nav-link-row {
		position: relative;
		z-index: 450;
		margin-top: -68px;
		margin-bottom: 0px;
		clear: left;
	}

	.nav-menu {
		position: relative;
		z-index: 500;
		background-color: #fff;
	}

	.slide-nav {
		height: 50px;
	}

	.button {
		display: none;
		font-size: 12px;
	}

	.div-block-8 .button {
		position: relative;
		top: 11px;
		display: flex;
		min-width: 180px;
	}

	.login-field {
		width: 49%;
		margin-bottom: 10px;
		float: left;
	}

	.login-form-wrapper {
		display: inline-block;
		width: 100%;
		height: auto;
		padding-right: 10px;
		padding-left: 30px;
		float: left;
		opacity: 0;
		transform: translate(0px, -74px);
		text-align: center;
	}

	.login-field-label {
		display: none;
	}

	.login-form {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}

	.login-div {
		left: 0px;
		top: 113px;
		right: 0px;
		bottom: 0px;
		display: flex;
		height: 210px;
		margin-top: -115px;
		margin-right: 0px;
		justify-content: flex-end;
		border-radius: 0px;
		text-align: center;
	}

	.button-2.login-button {
		width: 30%;
		float: none;
		margin: 0 auto;
	}

	.button-2.login-button {
		width: 30%;
		float: none;
		margin: 0 auto;
	}

	.news-section {
		position: relative;
		z-index: 500;
		padding-top: 20px;
		padding-bottom: 20px;
		flex-wrap: wrap;
		background-color: #fff;
	}

	.div-block-2 {
		width: 80%;
	}

	.search {
		position: relative;
		z-index: 460;
		margin-top: 20px;
	}

	.div-block-6 {
		margin-bottom: 0px;
	}

	.div-block-8 {
		position: relative;
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		z-index: 460;
		margin-top: -1px;
		margin-right: 50px;
		margin-bottom: 1px;
		background-color: transparent;
	}

	.callout-image {
		position: relative;
		z-index: 500;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.slider-graphic {
		position: relative;
		z-index: 410;
		padding-right: 140px;
		padding-bottom: 40px;
		background-position: 0% 62%;
		background-size: 750px;
	}

	.slider-graphic.slider-2 {
		justify-content: center;
		justify-items: center;
		align-content: center;
	}

	.heading-3 {
		font-size: 40px;
		line-height: 45px;
	}

	.text-block {
		font-size: 15px;
		line-height: 20px;
	}

	.heading-4 {
		margin-top: 95px;
		font-size: 35px;
		line-height: 40px;
	}

	.text-block-5 {
		font-size: 15px;
		line-height: 20px;
	}

	.testimonial {
		position: relative;
		z-index: 500;
	}

	.footer-container {
		position: relative;
		z-index: 500;
	}

	.heading-6 {
		background-size: 40px;
		font-size: 30px;
		line-height: 35px;
	}

	.div-block-12 {
		justify-content: center;
		flex-wrap: wrap;
	}

	.heading-7 {
		background-size: 40px;
	}

	.slider-4 {
		width: 450px;
	}

	.right-arrow-2 {
		margin-right: -12px;
		padding-right: 0px;
	}

	.contact-us {
		position: relative;
		z-index: 500;
		background-color: #fff;
	}

	.div-block-16 {
		justify-content: center;
		flex-wrap: wrap;
	}

	.div-block-17 {
		justify-content: center;
	}

	.div-block-18 {
		width: 100%;
		justify-content: center;
	}

	.image-8 {
		width: 40%;
	}

	.image-9 {
		width: 20%;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
	}

	.footer-copyright {
		float: none;
	}

	.column-3 {
		overflow: hidden;
		align-items: center;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.columns {
		flex-wrap: wrap;
	}

	.div-block-19 {
		margin-bottom: 0px;
	}

	.menu-button {
		position: static;
		margin: 0px 6px 18px 8px;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.footer-nav {
		width: 100%;
		padding-right: 0px;
		padding-bottom: 10px;
		padding-left: 0px;
		flex-direction: column;
	}

	.footer-nav-li {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
	}

	.footer-nav-a {
		font-size: 16px;
	}

	.facebook-label {
		font-size: 16px;
	}

	.section-container {
		position: relative;
		z-index: 500;
		display: flex;
		padding-right: 10px;
		padding-left: 10px;
		justify-content: center;
	}

	.div-block-21 {
		margin-bottom: 0px;
	}

	.account-button-mobile {
		display: block;
		width: auto;
		font-size: 12px;
	}

	.div-block-22 {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 100;
		display: flex;
		width: 200px;
		height: auto;
		margin-top: 10px;
		margin-bottom: -48px;
		padding-top: 0px;
		padding-right: 10px;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		order: 0;
		flex: 0 auto;
	}

	.icon-7 {
		color: #4f95d0;
	}

	.mobile-search {
		margin-top: 20px;
	}

	.div-block-23 {
		width: 100%;
		word-wrap: break-word;
		min-width: 180px;
		margin-left: -30px;
	}

	.button-copy {
		display: none;
		font-size: 12px;
	}

	.slider-learn-more {
		margin-bottom: 0px;
	}

	.main-content-outer-wrap {
		flex-direction: column;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.container-3 {
		max-width: 550px;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.footer-nav-social {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-bottom: 10px;
		margin-left: auto;
	}

	.login-dropdown {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 10px;
		float: none;
	}

	.login-dropdown.login-field {
		width: 100%;
	}

	.login-dropdown.login-field {
		width: 100%;
	}

	.login-link {
		display: flex;
	}

	.form-block-2 {
		margin-bottom: 0px;
	}

	.login-link-copy {
		display: flex;
	}

	.text-block-10 {
		font-size: 30px;
	}

	.account-login-wrapper {
		display: flex;
		overflow: hidden;
		width: 276px;
		height: 340px;
		max-height: none;
		max-width: none;
		margin-top: 44px;
		justify-items: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	#loginFormContainerHomepage form {
		width: 100%;
	}

	#loginFormContainerHomepage .div-block-24 {
		flex-direction: column;
	}

	#loginFormContainerHomepage .div-block-24 .text-block-9 {
		display: none;
	}

	#loginFormContainerHomepage .button-2.login-button {
		width: auto;
	}

	.online-banking-heading {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.button-copy-2 {
		display: none;
		font-size: 12px;
	}

	.menu-button-2 {
		position: static;
		margin: 0px 6px 18px 8px;
	}

	.menu-button-2.w--open {
		background-color: transparent;
	}

	.nav-menu-5 {
		position: relative;
		top: -35%;
		z-index: 500;
		background-color: #fff;
	}

	.login-form fieldset {
		width: 100%;
		display: flex;
	}

	.login-field-2 {
		width: 49%;
		margin-bottom: 10px;
		float: left;
	}

	.login-div-2 {
		left: 0px;
		top: 113px;
		right: 0px;
		bottom: 0px;
		display: flex;
		height: 210px;
		margin-top: -115px;
		margin-right: 0px;
		justify-content: flex-end;
		border-radius: 0px;
		text-align: center;
	}

	.nav-link-row-2 {
		position: relative;
		z-index: 450;
		margin-top: -68px;
		margin-bottom: 0px;
		clear: left;
		width: 70px;
		align-self: flex-end;
	}

	.nav-link-2 {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.nav-link-2:hover {
		background-color: rgba(9, 180, 133, 0.12);
		color: #404040;
	}

	.account-button-mobile-2 {
		display: block;
		width: auto;
		background-color: #afeef9;
		background-image: url('/20181016082423/assets/images/lock_black.svg');
		color: #333;
		font-size: 12px;
	}

	.account-button-mobile-2:hover {
		border-style: solid;
		border-color: #afeef9;
		background-color: #fff;
		color: #333;
	}

	.icon-8 {
		color: #333;
	}

	.logo-overlay {
		display: none;
	}

	.account-login-cb {
		display: none;
		width: 100%;
		height: 50vh;
		margin-top: 0px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.paragraph {
		margin-bottom: 0.5rem;
		font-size: 0.85rem;
		line-height: 1.4rem;
	}

	.bg-color-wrapper {
		flex-wrap: wrap;
	}

	.slider-5 {
		position: static;
		z-index: 445;
		margin-top: 85px;
	}

	.slider-6 {
		width: 100%;
		height: 60vh;
		margin-top: 0px;
	}

	.heading-12 {
		font-size: 40px;
		line-height: 45px;
	}

	.online-banking-heading-2 {
		font-size: 35px;
		line-height: 40px;
	}

	.main-content {
		padding-top: 0;
	}

	.div-block-3 {
		height: 65%;
	}

	.image-3 {
		height: 100%;
	}

	.div-block-4 {
		height: 65%;
	}

	.div-block-5 {
		height: 65%;
	}
}

@media screen and (max-width: 479px) {
	.section-3.no-banner {
		background: none !important;
		height: 10px;
	}

	.div-block-8 .button {
		display: none;
	}

	.slider {
		width: auto;
		height: auto;
		max-width: 100%;
		margin-top: 106px;
	}

	.navigation {
		position: absolute;
		z-index: 999;
		margin-top: 98px;
	}

	.slider-2 {
		width: auto;
		height: auto;
		max-width: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		background-color: #213064;
		flex-direction: column;
	}

	.link-block {
		width: 80%;
	}

	.icon-row {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.top-nav {
		padding-top: 10px;
		padding-bottom: 0px;
	}

	.navbar {
		position: fixed;
		width: 100%;
		flex-direction: column;
		align-items: stretch;
		background-color: #e7e7e7;
	}

	.brand {
		max-width: 245px;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 0px;
	}

	.nav-link-row {
		position: relative;
		display: flex;
		height: auto;
		margin-top: 0px;
		flex-direction: row;
		justify-content: space-between;
		align-self: flex-end;
	}

	.nav-menu {
		margin-top: 0px;
	}

	.slide-arrow {
		font-size: 21px;
	}

	.slide-arrow-2 {
		font-size: 21px;
	}

	.left-arrow {
		z-index: 30;
		display: block;
		width: 40px;
	}

	.right-arrow {
		z-index: 30;
		width: 40px;
		height: 50px;
	}

	.slide-nav {
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		font-size: 12px;
	}

	.slide-nav.slider-2 {
		display: flex;
		clear: none;
		justify-content: center;
		align-items: flex-end;
	}

	.button {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 600;
		display: flex;
		width: 136px;
		margin-top: 8px;
		padding: 5px 15px 5px 35px;
		background-size: 15px;
		font-size: 11px;
	}

	.slide {
		padding-top: 140px;
		background-position: 0px 0px, 55% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.login-field {
		display: inline-block;
		width: 100%;
		max-width: 250px;
		min-width: 0px;
		margin-top: -4px;
		float: none;
	}

	.login-form-wrapper {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 10px;
		padding-right: 10px;
		padding-left: 10px;
		float: none;
		opacity: 0;
		transform: translate(0px, -74px);
	}

	.login-field-label {
		display: none;
	}

	.login-form {
		display: flex;
		width: 300px;
		flex-direction: column;
		align-items: center;
	}

	.login-div {
		top: 89px;
		height: 228px;
		margin-top: -45px;
		flex-direction: row;
	}

	.button-2.login-button {
		display: inline-block;
		width: 82%;
		float: none;
	}

	.button-2.login-button {
		display: inline-block;
		width: 83%;
		margin-right: 5px;
		margin-left: 0px;
		padding-right: 12px;
		padding-left: 12px;
		float: none;
		background-color: #4f95d0;
		opacity: 1;
		color: #fff;
	}

	.news-section {
		padding: 0px;
	}

	.div-block-2 {
		width: 100%;
	}

	.div-block-3 {
		border-radius: 0px;
	}

	.heading-2 {
		margin-top: 20px;
		margin-right: 20px;
		margin-left: 20px;
		font-size: 20px;
		line-height: 25px;
	}

	.image-3 {
		border-radius: 0px;
	}

	.div-block-4 {
		border-radius: 0px;
	}

	.div-block-5 {
		border-radius: 0px;
	}

	.search {
		display: none;
		margin-top: 0px;
		margin-bottom: 0px;
		justify-content: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.search-input {
		display: none;
		height: 30px;
		margin-right: -30px;
	}

	.search-button {
		width: 30px;
		height: 30px;
	}

	.div-block-6 {
		margin-right: 20px;
		margin-left: 20px;
	}

	.div-block-8 {
		display: none;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 5px;
	}

	.slider-graphic {
		height: auto;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-left: 0px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.slider-graphic.slider-2 {
		display: flex;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		border-style: solid;
		border-color: #000;
		background-color: transparent;
		background-image: none;
	}

	.slide-2 {
		padding-top: 140px;
		background-position: 0px 0px, 55% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.heading-3 {
		font-size: 25px;
		line-height: 30px;
	}

	.text-block {
		font-size: 15px;
		line-height: 20px;
	}

	.blue-button {
		font-size: 12px;
	}

	.text-block-2 {
		margin-right: 20px;
		margin-left: 20px;
	}

	.text-block-3 {
		margin-right: 20px;
		margin-left: 20px;
	}

	.text-block-4 {
		margin-right: 20px;
		margin-left: 20px;
	}

	.heading-4 {
		margin-top: 164px;
		padding-top: 0px;
		margin-bottom: 0px;
		font-size: 28px;
		line-height: 25px;
	}

	.text-block-5 {
		font-size: 13px;
	}

	.testimonial {
		height: 400px;
		padding-bottom: 10px;
		padding-left: 0px;
		align-items: center;
	}

	.paragraph-2 {
		width: auto;
		max-width: none;
		padding-left: 0px;
		text-align: left;
	}

	.heading-6 {
		margin-bottom: 6px;
		margin-left: 18px;
		padding-left: 50px;
		font-size: 20px;
	}

	.heading-7 {
		margin-left: 20px;
		padding-left: 40px;
		background-size: 35px;
		font-size: 20px;
		line-height: 35px;
	}

	.slider-4 {
		width: 100%;
		height: 133px;
		margin-right: 10px;
		margin-left: 10px;
	}

	.right-arrow-2 {
		position: absolute;
		left: auto;
		top: auto;
		right: 0%;
		bottom: 0%;
		height: 20px;
		margin-right: 10px;
	}

	.left-arrow-2 {
		left: 0%;
		top: auto;
		right: auto;
		bottom: 0%;
		height: 20px;
		margin-left: 10px;
	}

	.slide-3 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.slide-4 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.text-block-7 {
		font-size: 20px;
		line-height: 25px;
	}

	.button-no-outline {
		font-size: 12px;
	}

	.div-block-16 {
		justify-content: center;
		flex-wrap: wrap;
		align-items: stretch;
	}

	.link-block-2 {
		width: 80%;
		margin-right: 0px;
		margin-bottom: 20px;
	}

	.link-block-2.logo-size.logo-size-mob {
		width: 40%;
	}

	.div-block-17 {
		justify-content: center;
		flex-wrap: wrap;
	}

	.div-block-18 {
		width: 100%;
		justify-content: center;
	}

	.image-8 {
		width: 50%;
	}

	.image-9 {
		width: 33%;
	}

	.text-block-8 {
		text-align: center;
	}

	.footer-signoff-link-wrap {
		text-align: center;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.column-3 {
		margin-bottom: 0rem;
	}

	.column-2 {
		padding-left: 0px;
		flex-direction: column;
		align-items: center;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.columns {
		flex-wrap: wrap;
	}

	.slide-butotn {
		padding-top: 10px;
	}

	.div-block-19 {
		padding-left: 0px;
	}

	.news-see-all {
		margin-bottom: 13px;
		margin-left: 10px;
	}

	.div-block-20 {
		width: 100%;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.testimonials-see-all {
		height: 45px;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 10px;
	}

	.menu-button {
		position: fixed;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 999;
		width: auto;
		height: 50px;
		margin: 3px 0px 46px 263px;
	}

	.footer-nav {
		width: 90%;
		padding-bottom: 10px;
	}

	.footer-nav-a.blue {
		font-size: 15px;
	}

	.footer-nav-a.orange {
		font-size: 15px;
	}

	.facebook-label {
		font-size: 15px;
	}

	.div-block-21 {
		padding-top: 10px;
		padding-bottom: 10px;
		width: 100%;
		justify-content: space-between;
	}

	.account-button-mobile {
		display: none;
		padding: 5px 15px 5px 35px;
		background-size: 15px;
		font-size: 11px;
	}

	.div-block-22 {
		left: 142px;
		justify-content: flex-end;
		align-items: center;
	}

	.icon-7 {
		display: flex;
	}

	.mobile-search {
		position: relative;
		z-index: 600;
		display: block;
		width: 30px;
		height: 30px;
		margin-top: 7px;
		margin-right: 50px;
		margin-bottom: 9px;
		padding-bottom: 0px;
		align-self: flex-end;
	}

	.div-block-23 {
		margin-top: auto;
		margin-left: 0px;
		padding: 0px 20px;
		background-color: transparent;
	}

	.button-copy {
		z-index: 1000;
		display: block;
		height: 30px;
		margin-top: 8px;
		margin-right: 0;
		margin-bottom: 8px;
		padding: 5px 16px 5px 30px;
		flex-direction: column;
		align-items: center;
		background-position: 10px 49%;
		background-size: 15px;
		font-size: 11px;
	}

	.slider-learn-more {
		margin-right: 20px;
		margin-left: 0px;
		align-items: flex-start;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.main-content {
		padding-top: 0;
	}

	.container-3 {
		max-width: none;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.content-outer-wrap {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.login-dropdown {
		width: 100%;
		margin-bottom: 10px;
		float: none;
	}

	.form-block {
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		opacity: 0;
	}

	.div-block-25 {
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 40%, #fff), url('/20181016082423/assets/images/Happy-Customers-mobile.jpg');
		background-position: 0px 0px, 53% 50%;
		background-size: auto, cover;
		background-clip: border-box;
		-webkit-text-fill-color: inherit;
	}

	.login-link {
		padding-right: 0px;
		padding-left: 0px;
		align-self: flex-start;
		text-align: left;
	}

	.text-block-10 {
		width: 83%;
		margin-top: 94px;
		font-size: 20px;
		line-height: 28px;
	}

	.account-login-wrapper {
		position: fixed;
		overflow: hidden;
		width: 100%;
		height: 45%;
		padding: 0 10%;
		justify-content: center;
		align-items: center;
		grid-column-gap: 2px;
		grid-row-gap: 2px;
		opacity: 1;
		line-height: 20px;
		margin: 106px 0 0;
		display: none;
		background-color: transparent;
		background-image: unset;
		z-index: 1000;
	}

	.account-login-wrapper .online-banking-heading {
		display: none;
	}

	.account-login-wrapper .login-form {
		width: 100%;
	}

	#loginFormContainerHomepage {
		background-color: #fff;
		padding: 10px 10px 20px;
	}

	#loginFormContainerHomepage .login-field,
	#loginFormContainerHomepage .login-dropdown {
		background-color: #e8f0fe;
	}

	#loginFormContainerHomepage form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#loginFormContainerHomepage .button-2.login-button {
		width: 100%;
		max-width: 250px;
		border-radius: 3px;
		margin-top: 0rem;
	}

	#loginFormContainerHomepage .div-block-24 {
		flex-direction: row;
		align-items: baseline;
		margin-top: 0.5rem;
	}

	#loginFormContainerHomepage .div-block-24 .text-block-9 {
		display: block;
		color: #616265;
	}

	#loginFormContainerHomepage .slide-link.enroll {
		display: block;
		text-align: left;
		color: #616265;
	}

	.div-block-26 {
		width: 100%;
		flex-direction: row;
	}

	.login-button {
		padding-right: 30px;
		padding-left: 30px;
		justify-content: flex-start;
		font-size: 12px;
		text-align: center;
	}

	.online-banking-form {
		display: block;
		height: auto;
	}

	.online-banking-heading {
		margin-bottom: 5px;
		font-size: 18px;
		line-height: 25px;
	}

	.image-12 {
		width: 62%;
	}

	.error-message {
		float: none;
	}

	.text-block-11 {
		text-align: left;
	}

	.button-copy-2 {
		position: absolute;
		left: auto;
		top: auto;
		right: 0%;
		bottom: auto;
		z-index: 600;
		display: block;
		width: 30px;
		height: 30px;
		margin-top: 0px;
		margin-right: 12px;
		padding: 5px 18px 5px 0px;
		flex-direction: row;
		border-style: solid;
		border-color: transparent;
		background-color: #4f95d0;
		background-image: url('/20181016082423/assets/images/lock_black.svg');
		background-position: 50% 50%;
		background-size: 13px;
		color: #fff;
		font-size: 11px;
	}

	.button-copy-2:hover {
		border-style: solid;
		border-color: #213064;
		background-color: #fff;
	}

	.search-button-2 {
		display: none;
		width: 30px;
		height: 30px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 0px;
		background-size: 12px;
	}

	.search-button-2:hover {
		background-size: 13px;
	}

	.menu-button-2 {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 999;
		width: auto;
		height: 50px;
		margin: 3px auto 46px;
	}

	.menu-button-2.w--open {
		display: block;
	}

	.nav-menu-5 {
		display: none;
		margin-top: 0px;
	}

	#loginFormContainerHeader .login-dropdown-container {
		width: 100%;
	}

	.login-form fieldset {
		flex-direction: column;
		align-items: center;
	}

	.login-field-2 {
		display: inline-block;
		width: 100%;
		max-width: 250px;
		min-width: 0px;
		margin-top: -4px;
		float: none;
	}

	.login-div-2 {
		top: 88px;
		z-index: auto;
		display: block;
		height: 248px;
		margin-top: -45px;
		margin-left: 0px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		opacity: 1;
	}

	.login-form .link-3 {
		text-align: center;
		line-height: 18px;
	}

	.nav-link-row-2 {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		margin-top: 0px;
		justify-content: space-between;
		align-self: flex-end;
	}

	.navbar-2 {
		display: flex;
		width: 100%;
		margin-right: 0px;
		padding-right: 0px;
		justify-content: center;
		align-items: center;
		background-color: #e7e7e7;
	}

	.nav-link-2:hover {
		background-color: rgba(33, 48, 100, 0.14);
	}

	.account-button-mobile-2 {
		display: none;
		padding: 5px 15px 5px 35px;
		background-size: 15px;
		font-size: 11px;
	}

	.mask-3 {
		overflow: hidden;
		width: auto;
		height: auto;
	}

	.logo-overlay {
		left: 0%;
		top: auto;
		right: 0%;
		bottom: -40%;
		height: 300px;
	}

	.account-login-cb {
		height: 60vh;
	}

	.blue-button-2 {
		font-size: 12px;
	}

	.slider-5 {
		margin-top: 100px;
	}

	.login-button-2 {
		font-size: 12px;
	}

	.heading-12 {
		font-size: 25px;
		line-height: 30px;
	}

	.slide-6 {
		background-position: 50% 50%;
	}

	.online-banking-heading-2 {
		font-size: 20px;
		line-height: 25px;
	}
}

/******************** Webflow ***********************/
/****************************************************/

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
#header {
	position: sticky;
	top: 0;
	z-index: 1000 !important;
	width: 100%;
}

.login-div-2 {
	display: none;
	margin-top: 0px;
	top: 41px;
}

.form-block {
	margin-top: 0px;
	transform: none !important;
	opacity: 1 !important;
}

.alert-section {
	padding: 18px;
	background-color: #3079b5;
	text-align: center;
	color: #fff;
}

.alert-section .cms-website-content-region p {
	color: #fff;
}

.alert-section .cms-website-content-region p:last-of-type {
	margin-bottom: 0;
}

.alert-section .cms-website-content-region h2,
.alert-section .cms-website-content-region h3,
.alert-section .cms-website-content-region h4,
.alert-section .cms-website-content-region h5,
.alert-section .cms-website-content-region h6,
.alert-section .cms-website-content-region td,
.alert-section .cms-website-content-region li,
.alert-section .cms-website-content-region span {
	color: #fff;
}

.header-left-wrap {
	position: relative;
	display: flex;
	padding-top: 0;
	justify-content: space-around;
	align-items: flex-start;
	flex-direction: column;
	row-gap: 0.6rem;
}

.fdic {
	grid-column-gap: 7px;
	grid-row-gap: 7px;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;
	display: flex;
}

.fdic .fdic-logo {
	background-image: url('/assets/images/fdic-logo.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: contain;
	min-width: 39.76px;
	height: 16px;
}

.fdic .fdic-text {
	color: #000;
	font-family: "Source Sans 3", sans-serif;
	font-weight: normal;
	font-size: 12.8px;
	font-style: italic;
	line-height: 13px;
	text-align: left;
	margin-top: 3px;
	margin-right: 7px;
}

.alert-wrap-fdic {
	padding: 6px 100px;
	background-color: #213064;
	width: 100%;
}

.alert-wrap-fdic p,
.alert-wrap-fdic li,
.alert-wrap-fdic a,
.alert-wrap-fdic td,
.alert-wrap-fdic span,
.alert-wrap-fdic h1,
.alert-wrap-fdic h2,
.alert-wrap-fdic h3,
.alert-wrap-fdic h4,
.alert-wrap-fdic h5,
.alert-wrap-fdic h6 {
	color: #fff !important;
}

.sticky .alert-wrap-fdic {
	display: none;
}

.alert-wrap-fdic table td {
	padding: 5px 5px;
	vertical-align: middle;
}

.alert-wrap-fdic td,
.alert-wrap-fdic p {
	font-family: "Source Sans 3", sans-serif;
	font-weight: 400;
	font-size: 12.8px;
	font-style: italic;
	line-height: 13px;
}

.div-block-21 {
	padding: 0px 0px;
	flex-wrap: wrap;
}

.header-inner-container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 20px 100px 20px 100px;
	justify-content: flex-start;
}


.header-left-wrap {
	flex-grow: 1;
}

@media screen and (min-width: 992px) {
	#header.sticky .div-block-21 {
		height: 0px;
		padding: 0px;
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.image-12 {
		height: 62px;
		width: auto;
	}

	.div-block-8 {
		margin-top: -10px;
	}

	.login-div-2 {
		top: 0px;
	}

	.sticky .alert-wrap-fdic {
		display: inline-block;
	}

	.header-inner-container,
	.alert-wrap-fdic {
		padding-right: 10px;
		padding-left: 40px;
	}

	.menu-button-2 {
		margin-top: 0px;
	}

	.navigation {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.image-12 {
		height: 50px;
		margin-top: -12px;
	}

	.div-block-8 {
		margin-top: -5px;
		margin-bottom: 5px;
	}

	.login-div-2 {
		top: -28px;
		margin-right: 20px;
	}

	.header-inner-container,
	.alert-wrap-fdic {
		padding-right: 10px;
		padding-left: 10px;
		position: relative;
	}

	.div-block-8 {
		margin-right: 0px;
	}
}

@media screen and (max-width: 479px) {
	.image-12 {
		height: 47px;
		margin-top: -5px;
		width: auto;
	}

	.login-div-2 {
		top: 45px;
		margin-right: 20px;
		margin-left: 20px;
	}

	.header-left-wrap {
		width: 100%;
	}

	.top-nav {
		position: relative;
	}

	.navigation {
		position: static;
		margin: 0px;
	}

	.navigation {
		display: block;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-item-li {
	display: flex;
	position: relative;
}

.accesible-navigation-menu li.nav-item-li .nav-link-2 {
	padding: 20px;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	border-bottom: 19px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	margin-top: 0px;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul {
	display: none;
	background-color: #fff;
	margin: 0px;
	list-style-type: none;
	min-width: 210px;
	padding: 0;
	width: 100%;
	border: 1px solid rgba(33, 48, 100, 0.11);
	border-top: 0px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul li {
	display: flex;
	width: 100%;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul a {
	padding: 0.725rem;
	border-top: 1px solid rgba(95, 87, 76, 0.09);
	border-bottom: 1px solid transparent;
	font-family: Montserrat, sans-serif;
	color: #404040;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	text-decoration: none;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul a.w--current {
	background-color: rgba(33, 48, 100, 0.11);
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper,
.accesible-navigation-menu li.nav-item-li:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper ul,
.accesible-navigation-menu li.nav-item-li:hover .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mm-menu_position-bottom.mm-menu_opened,
.mm-menu_position-front.mm-menu_opened,
.mm-menu_position-top.mm-menu_opened {
	z-index: 2000;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #fff;
	padding: 0;
}

.mobile-navigation-menu li a.nav-a {
	padding-top: 30px;
	padding-bottom: 30px;
	color: #213064;
	text-transform: none;
	transition: all 450ms ease;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	line-height: 0px;
	font-weight: 600;
}

.mobile-navigation-menu .mm-navbar__title span {
	color: #213064 !important;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 600;
}

.mobile-navigation-menu li a.nav-a.active,
.mobile-navigation-menu li a.nav-a:hover {
	background-color: rgba(33, 48, 100, 0.14);
	color: #404040;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-listitem:after {
	left: 0;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-menu_position-top {
	height: calc(100vh - 91px);
	margin-top: 91px;
	padding-bottom: 91px;
	min-height: 100vh;
	max-height: 100vh;
}

@media screen and (max-width: 767px) {
	.mm-menu_position-top {
		height: calc(100vh - 58px);
		margin-top: 58px;
		padding-bottom: 58px;
		min-height: 100vh;
		max-height: 100vh;
	}
}

@media screen and (max-width: 479px) {
	.mm-menu_position-top {
		height: calc(100vh - 106px);
		padding-bottom: 106px;
		min-height: 100vh;
		max-height: 100vh;
	}
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cbtext.white h1,
.cbtext.white h2,
.cbtext.white h3,
.cbtext.white h4,
.cbtext.white h5,
.cbtext.white h6,
.cbtext.white td,
.cbtext.white li,
.cbtext.white span,
.cbtext.white a {
	color: #fff;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.slider-text h1,
.slider-text h2,
.slider-text h3,
.slider-text h4,
.slider-text h5,
.slider-text h6,
.slider-text td,
.slider-text li,
.slider-text span,
.slider-text a {
	color: #fff;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.slider,
.alert-section {
	margin-top: 0;
}

.alert-section + .slider {
	margin-top: 0px !important;
}

.heading-4 {
	line-height: 44px;
}

.w-slider-mask,
.w-slide {
	height: auto;
}

.slider-graphic {
	min-height: 650px;
}

@media screen and (min-width: 992px) {
	.w-slider-mask,
	.w-slide {
		height: 650px !important;
	}
}

@media screen and (max-width: 991px) {
	.slider,
	.alert-section {
		margin-top: 0;
	}

	.slider-graphic {
		min-height: 500px;
	}
}

@media screen and (max-width: 767px) {
	.slider {
		margin-top: 0;
	}

	.slider-graphic {
		min-height: 450px;
	}

	.alert-section {
		margin-top: 80px;
	}
}

@media screen and (max-width: 479px) {
	.slider,
	.alert-section {
		margin-top: 0;
	}

	.slider-graphic {
		min-height: 530px;
		display: block;
		padding-top: 140px;
	}

	.slide {
		padding-top: 0px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child {
	margin-top: 2px;
}

.breadcrumbs-list li:last-child a {
	border-bottom-style: none !important;
	color: #213064 !important;
}

.left-nav-link-3.active {
	background-color: rgba(255, 255, 255, 0.47);
	transition-duration: 450ms;
	color: #213064;
	text-transform: none;
}

.left-nav-link-3.grandchild-link.active {
	background-color: rgba(255, 255, 255, 0.47);
	background-image: none;
	color: #213064;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link.active {
	background-color: rgba(255, 255, 255, 0.47);
	color: #213064;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.section-3 {
	margin-top: 143px;
}

@media (max-width: 991px) {
	.section-3 {
		margin-top: 0;
	}

	.breadcrumbs-list li:last-child {
		margin-top: 0px;
	}

	.breadcrumbs-list {
		margin-top: 0px;
	}
}

@media (max-width: 767px) {
	.section-3 {
		margin-top: 0;
	}
}

@media (max-width: 479px) {
	.section-3 {
		margin-top: 0;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-side-col td img {
	max-width: none;
}

.right-side-col .box {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
	margin-top: 10px;
}

.right-side-col .box .content {
	font-size: 14px;
	line-height: 20px;
	color: #333;
	font-family: Montserrat, sans-serif;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	margin-bottom: 10px;
	color: #213064;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-top: 0px;
	font-family: Montserrat, sans-serif;
}

.right-side-col .box .content p {
	font-size: 14px;
	line-height: 20px;
	color: #333;
	font-family: Montserrat, sans-serif;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col a {
	border-bottom: 1px solid #404040;
	background-color: transparent;
	transition: all 300ms ease;
	color: #404040;
	text-decoration: none;
}

.right-side-col .box.links a,
.right-side-col .box.documents a {
	font-weight: 600;
}

.right-side-col a:hover {
	border-bottom: 1px none transparent;
	background-color: transparent;
	color: #213064;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.main-content a {
	border-bottom: 1px solid #2f6ca0;
	transition: all 450ms ease;
	color: #696b6d;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
}

.left-sidebar a:hover,
.main-content a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #213064;
}

h1 {
	color: #424242;
	font-size: 40px;
	line-height: 55px;
	font-weight: 700;
}

h2 {
	color: #4a4a4a;
	font-size: 26px;
	font-weight: 600;
}

h3,
h4,
h5,
h6 {
	color: #4a4a4a;
}

h1 a {
	transition: all 300ms ease;
	color: #213064 !important;
	font-size: inherit !important;
	line-height: inherit !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 600 !important;
}

h1 a:hover {
	color: #2f6ca0 !important;
}

h2 a {
	transition: all 300ms ease;
	color: #213064 !important;
	font-size: 32px !important;
	line-height: 25px !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 600 !important;
}

h2 a:hover {
	color: #2f6ca0 !important;
}

h3 a {
	transition: all 450ms ease;
	color: #213064 !important;
	font-size: 24px !important;
	line-height: 30px !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 700 !important;
}

h3 a:hover {
	color: #2f6ca0 !important;
}

h4 a {
	transition: all 450ms ease;
	color: #213064 !important;
	font-size: 18px !important;
	line-height: 24px !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 700 !important;
}

h4 a:hover {
	color: #2f6ca0 !important;
}

h5 a {
	transition: all 450ms ease;
	color: #213064 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 700 !important;
}

h5 a:hover {
	color: #2f6ca0 !important;
}

h6 a {
	transition: all 450ms ease;
	color: #213064 !important;
	font-size: 12px !important;
	line-height: 18px !important;
	text-decoration: underline !important;
	border-bottom: none !important;
	font-weight: 700 !important;
}

h6 a:hover {
	color: #2f6ca0 !important;
}

p {
	color: #4a4a4a;
	font-size: 16px;
	line-height: 22px;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 0.8rem 1rem 0.7rem;
	border-style: solid !important;
	border-width: 2px !important;
	border-color: #3079b5 !important;
	border-radius: 50px;
	background-color: #3079b5 !important;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff !important;
	line-height: 1rem !important;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
	min-width: 100px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	padding-bottom: 0.82rem !important;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	border-style: solid !important;
	border-width: 2px !important;
	border-color: #4f95d0 !important;
	background-color: #fff !important;
	color: #213064 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	padding: 0.8rem 1rem 0.7rem;
	border-style: solid !important;
	border-width: 2px !important;
	border-color: #213064 !important;
	border-radius: 50px;
	background-color: #213064 !important;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff !important;
	line-height: 1rem !important;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
	min-width: 100px;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	border-color: #213064 !important;
	background-color: #fff !important;
	color: #213064 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border-radius: 50px;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1 !important;
	background-color: transparent !important;
	color: #585b5d !important;
	font-size: 0.9rem;
	line-height: 1.4rem !important;
	min-width: 100px;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	background-color: #f1f1f1 !important;
	color: #000 !important;
}

/* messages */
#message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-style: solid;
	border-width: 2px;
	border-color: #0b5d37;
	background-color: #caf3aa;
	color: #000;
	font-size: 1.125rem;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #213064;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #3079b5;
	color: #ffffff;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content-inner-wrap input[type="text"],
.main-content-inner-wrap input[type="tel"],
.main-content-inner-wrap input[type="password"],
.main-content-inner-wrap input[type="email"],
.main-content-inner-wrap select,
.main-content-inner-wrap textarea,
.main-content-inner-wrap input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
}

.main-content-inner-wrap textarea {
	resize: vertical;
}

.main-content-inner-wrap label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.main-content-inner-wrap input[type="text"],
	.main-content-inner-wrap input[type="tel"],
	.main-content-inner-wrap input[type="password"],
	.main-content-inner-wrap input[type="email"],
	.main-content-inner-wrap select,
	.main-content-inner-wrap textarea,
	.main-content-inner-wrap input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-width: 9px;
	border-left-color: #213064;
	background-color: #e7e7e7;
	color: #4a4a4a;
	font-size: 1.35rem;
	line-height: 2rem;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.875em;
	line-height: 1.25em;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	font-weight: 700;
	text-transform: uppercase;
	border-bottom: 1px solid transparent;
	font-family: Roboto, sans-serif;
	color: #394486;
	font-style: normal;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.phone-mobile {
	display: none;
	color: #333;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.phone-desktop {
	display: inline-block;
	text-decoration: none !important;
	border-bottom: 1px solid transparent;
}

.footer-signoff-links {
	text-decoration: none;
	color: #333;
	line-height: 1.25rem;
	font-size: 14px;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	.paragraph-5.phone {
		margin-bottom: 8.5px;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}

	.footer-navigation-container {
		width: 60%;
	}

	.footer-nav-a.orange {
		font-size: 14px;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		margin-top: 1rem;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

/* mailing lists */
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 12px 0 20px;
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 0px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* gdpr */
#cookie-consent-container .consent-all-trigger,
#cookie-consent-container .consent-all-trigger:hover,
#cookie-consent-container .reject-all-trigger,
#cookie-consent-container .reject-all-trigger:hover {
	padding: 0 !important;
	background: none !important;
	color: #fff !important;
	border: 0px !important;
	border-radius: 0px !important;
	border-bottom: 1px dotted #fff !important;
	font-size: 0.875rem !important;
	line-height: 20px !important;
	min-width: auto !important;
}

#cookie-consent-form-container form .consent-header {
	background-color: #fff;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	text-align: center;
	display: block;
	max-width: 460px;
}

#cookie-consent-container {
	padding-right: 5rem;
	background: #213064;
}

#cookie-consent-container.minimized {
	background: #262626;
}

#cookie-consent-container .consent-actions a {
	font-weight: 600;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	border: 1px solid transparent;
}

#cookie-consent-form-container form {
	top: 4rem;
}

#cookie-consent-container .consent-disclaimer {
	padding-right: 5%;
	padding-left: 7%;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-disclaimer {
		width: 79%;
		padding-right: 5%;
		padding-left: 5%;
	}

	#cookie-consent-container .consent-actions {
		width: 21%;
	}
}

@media screen and (max-width: 991px) {
	#cookie-consent-form-container form {
		top: 7rem;
	}
}

@media screen and (max-width: 767px) {
	#cookie-consent-container {
		padding-right: 2rem;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
	}

	#cookie-consent-container .consent-actions {
		width: 100%;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/********************** Alert Box ******************/
/***************************************************/
.alert-wrap {
	display: none;
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}

.alert-wrap .alert-wrap-content {
	background-color: #ffff;
	margin: 200px auto;
	max-width: 650px;
	width: 80%;
	text-align: center;
	color: #fff;
	min-height: 200px;
	box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.75);
}

.alert-wrap .alert-wrap-content > div {
	padding: 30px 6vw;
}

.alert-wrap .alert-close-btn {
	padding: 0px;
	margin: 7px 7px 0px 0px;
	color: #fff;
	border: 0px;
	float: right;
	width: 25px;
	height: 25px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 20px;
	background-image: url('/assets/images/ic-close.png');
	background-position: center;
	cursor: pointer;
}


/* TCAL calculators custom styles */
.cTimeValue a#A_CalculatorDisclaimer {
	display: inline-block;
	margin-top: 2rem;
}

.cTimeValue,
.cTimeValue INPUT,
.cTimeValue P,
.cTimeValue UL,
.cTimeValue FIELDSET {
	font-family: Montserrat, sans-serif;
}

.cTimeValue H2,
.cTimeValue H3 {
	font-family: Montserrat, sans-serif;
}

.cTimeValue #COMPUTE,
.cTimeValue #tvcCOMPUTE {
	font-family: Montserrat, sans-serif;
	font-weight: bold;
}

.cTimeValue label {
	font-weight: bold;
}

.cTimeValue .InputContainer label {
	display: inline-block;
}

.cTimeValue .InputContainer input[type="checkbox"] {
	margin-right: 1rem;
}

.cTimeValue #COMPUTE,
.cTimeValue #tvcCOMPUTE {
	padding: 0.8rem 1rem 0.7rem;
	border-style: solid !important;
	border-width: 2px !important;
	border-color: #4f95d0 !important;
	border-radius: 50px;
	background-color: #4f95d0 !important;
	transition: all 0.2s ease;
	font-family: Montserrat, sans-serif;
	color: #fff !important;
	line-height: 1rem !important;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
	min-width: 100px;
	background-image: unset;
	width: auto;
	height: auto;
}

.cTimeValue #COMPUTE:hover,
.cTimeValue #tvcCOMPUTE:hover {
	border-style: solid !important;
	border-width: 2px !important;
	border-color: #4f95d0 !important;
	background-color: #fff !important;
	color: #213064 !important;
}


.cTimeValue .cAmortContainer .cAmortTitleFont {
	font-size: 1rem;
	line-height: 1.3rem;
}

.cTimeValue .cAmortContainer fieldset {
	padding: 1rem 0;
}

.cTimeValue .cAmortContainer .cAmortHeader th {
	font-family: Montserrat, sans-serif;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #213064;
	background-image: unset;
	color: #fff;
	font-size: 1rem;
	line-height: 1.2rem;
}

.cTimeValue .cAmortContainer .cAmortTable tbody td {
	font-family: Montserrat, sans-serif;
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

.cTimeValue .cAmortContainer .cAmortTable tbody td tr:nth-child(even) {
	background: #ebeff2;
}

.cTimeValue .Summary {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 1rem 1.5rem;
	border-left-style: solid;
	border-left-width: 9px;
	border-left-color: #213064;
	background-color: #e7e7e7;
}

.cTimeValue .Summary p {
	color: #000;
	font-size: 1.125rem;
}

